import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import App from "./App.vue";
import router from "./router";
import { datadogRum } from "@datadog/browser-rum";
import { client } from "@/services/api.js";
import { setupI18n, createSpiels } from "@/utils/spiels";
import { useClientStore } from "@/stores/clientStore";

const setupLocale = (clientStore) =>
  clientStore.isAuthenticated && clientStore.region === "AU"
    ? "en-AU"
    : "en-NZ";

const setupI18nAndSpiels = async (locale) => {
  const i18n = await setupI18n(locale);
  return { i18n, spiels: createSpiels(i18n) };
};

const initializeApp = async () => {
  const app = createApp(App);
  const pinia = createPinia();

  pinia.use(piniaPluginPersistedstate);
  app.use(router).use(pinia);

  const clientStore = useClientStore();
  let locale = setupLocale(clientStore);

  const { i18n, spiels } = await setupI18nAndSpiels(locale);

  app.use(i18n);
  app.provide("spiels", spiels);

  clientStore.$subscribe(async () => {
    if (clientStore.isAuthenticated) {
      locale = setupLocale(clientStore);
      const { spiels } = await setupI18nAndSpiels(locale);
      app.provide("spiels", spiels);
    }
  });

  app.config.globalProperties.$axios = client;
  app.mount("#app");

  if (process.env.NODE_ENV === "production") {
    initDatadogRUM();
  }
};

const initDatadogRUM = () => {
  datadogRum.init({
    applicationId: "8e1453dd-f9a3-44f7-9346-c3c17c688243",
    clientToken: "pub2d3c0ddbbd04188ca5ba94c57895e719",
    site: "us3.datadoghq.com",
    service: "tax-admin",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
    allowedTracingUrls: [
      "https://aaapi.pluginaccountant.com",
      "https://api.pluginaccountant.com",
    ],
  });
};

initializeApp();
