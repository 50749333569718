import { client } from "./api";

export const updateMeApi = (data) => {
  return client
    .post("/api/user/me", data, {
      withCredentials: true,
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      console.error("e: ", e);
      return "";
    });
};

export const sendCustomEvent = (data) => {
  return client
    .post("/api/user/custom-event", data, {
      withCredentials: true,
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      console.error("e: ", e);
      return "";
    });
};

export const isLoggedIn = () => {
  return client
    .get("/api/user/is-logged-in")
    .then((response) => {
      return response;
    })
    .catch((e) => {
      console.error("e: ", e);
      return "";
    });
};
