<template>
  <div id="main-heading" :class="headerClass">
    <div id="header-heading" class="header-title">
      <h1>{{ label }}</h1>
    </div>
    <div class="header-bg">
      <div :class="bgClass"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaxHeader",
};
</script>
<script setup>
import { computed } from "vue";
const props = defineProps({
  label: String,
  hideBg: Boolean,
});

const headerClass = computed(() => ({
  header: true,
  "container-fluid": true,
  "gx-5": true,
  "header-section": true,
}));

const bgClass = computed(() => ({
  "right-bg": true,
  "hide-bg": props.hideBg,
}));
</script>
<style lang="scss" scoped>
@import "@/styles/variables";
@import "./Header.scss";
</style>
