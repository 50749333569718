// Functions that are used across pages or components can go in here
export const findParentRoute = (allRoutes, name) => {
  let found = null;

  allRoutes.forEach((r) => {
    if (r.name === name) {
      found = r;
    }
    (r.children || []).forEach((ch) => {
      if (ch.name === name) {
        found = r;
      }
    });
  });
  return found;
};

export const getAlphaByIndex = (index) => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const normalizedIndex = index % alphabet.length;
  const positiveIndex =
    normalizedIndex < 0 ? normalizedIndex + alphabet.length : normalizedIndex;
  return alphabet[positiveIndex];
};

export const isValidEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};
