import { client } from "@/services/api.js";

const becomeUser = async (payload) => {
  return await client.post("/api/user/become-user", payload);
};

const searchUsers = async (searchFilter) => {
  return await client.post("/api/user/search-users", searchFilter);
};

const signUp = async (payload) => {
  return await client.post("/api/user/create-test-user", payload);
};

const getMe = async () => {
  return await client.get("/api/user/me");
};

const updateMe = async (payload) => {
  try {
    return await client.post("/api/user/me", payload);
  } catch (error) {
    return "";
  }
};

const markBannerAsViewed = async (banner) => {
  return await client.post("/api/user/banner-viewed", { banner });
};

const changePassword = async (data) => {
  return await client.post("/api/user/change-password", data);
};

export {
  becomeUser,
  signUp,
  searchUsers,
  getMe,
  updateMe,
  markBannerAsViewed,
  changePassword,
};
