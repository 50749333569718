import { client } from "@/services/api.js";

export const login = (data) => {
  return client.post("/api/user/login", data);
};

export const logout = () => {
  return client.get("/api/user/logout").then(() => localStorage.clear());
};

export const validatePasswordToken = (token) => {
  return client.post(`/api/user/validateResetPasswordToken`, { token });
};

export const resetPassword = (data) => {
  return client.post("/api/user/resetPassword", data);
};

export const confirmResetPassword = (data) => {
  return client.post("/api/user/confirmResetPassword", data);
};

export const sendEmail = (email) => {
  return client.post("/api/user/recommend-desktop-email", email);
};
