import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { getMe, markBannerAsViewed } from "@/services/user";
import { getTinyMce } from "@/services/sysinfo";
import { getPartner } from "@/services/partner";
import { useRouter } from "vue-router";
import SimpleCrypto from "simple-crypto-js";

const SECRET_KEY = "]oN,R1L?r8N4sn{{ccFeS1oGrLDfK'"; //Random keygen
const simpleCrypto = new SimpleCrypto(SECRET_KEY);

export const useClientStore = defineStore(
  "store",
  () => {
    const router = useRouter();

    const client = ref({
      data: {},
      billingInfoUrl: "",
      role: "",
      partner: {},
      tinyMce: "",
    });

    const isLoading = ref(false);
    const loadingCount = ref(0);
    const loadingTimerId = ref(null);
    const LOADING_DELAY = 200; // milliseconds

    const isAuthenticated = computed(
      () => client.value.data.email !== undefined
    );

    const getItem = (item) => {
      const { role, partner, data, tinyMce } = client.value;

      switch (item) {
        case "role":
          try {
            return role ? simpleCrypto.decrypt(role) : [];
          } catch (e) {
            console.error(e);
            return [];
          }
        case "plan":
          try {
            return partner
              ? simpleCrypto.decrypt(partner).selectedPackage
              : null;
          } catch (e) {
            console.error(e);
            return null;
          }
        case "tinyMce":
          try {
            return tinyMce ? simpleCrypto.decrypt(tinyMce) : null;
          } catch (e) {
            console.error(e);
            return null;
          }
        default:
          return data[item];
      }
    };

    const isBannerViewed = (id) =>
      client?.value?.data?.closedBanners?.includes(id);

    const isProPlan = computed(() => getItem("plan") === "product_pro");
    const isSysAdmin = computed(() =>
      getItem("role").includes("group:sysadmin")
    );

    const isBetaGroup = computed(() => getItem("role").includes("group:beta"));

    const decodedPartner = computed(() => {
      if (isAuthenticated.value) {
        return simpleCrypto.decrypt(client.value.partner);
      }
      return null;
    });

    const region = computed(() => decodedPartner.value?.region || null);

    const setPartner = async () => {
      const partnerData = await getPartner();
      if (partnerData) {
        client.value.partner = simpleCrypto.encrypt(
          JSON.stringify(partnerData.data.data.properties)
        );
      } else {
        return undefined;
      }
    };

    const setTinyMce = async () => {
      client.value.tinyMce = await getTinyMce().then((resp) => {
        if (resp.data.tinyMce) {
          return simpleCrypto.encrypt(resp.data.tinyMce);
        }
      });
    };

    const startLoading = () => {
      loadingCount.value++;
      if (loadingTimerId.value === null) {
        loadingTimerId.value = setTimeout(() => {
          if (loadingCount.value > 0) {
            isLoading.value = true;
          }
        }, LOADING_DELAY);
      }
    };

    const stopLoading = () => {
      loadingCount.value--;
      if (loadingCount.value <= 0) {
        loadingCount.value = 0;
        clearTimeout(loadingTimerId.value);
        loadingTimerId.value = null;
        isLoading.value = false;
      }
    };
    const setClient = async () => {
      startLoading();
      try {
        await setTinyMce();
        await setPartner();

        client.value.data = await getMe().then((resp) => {
          client.value.role = simpleCrypto.encrypt(resp.data?.groups);
          if (!resp.data.welcomeViewed) {
            router.push({ name: "OnboardingWelcome" });
          } else {
            router.push({ name: "Operation ird" });
          }
          return resp.data;
        });
      } finally {
        stopLoading();
      }
    };

    const updateStoreMe = async () => {
      client.value.data = await getMe().then((resp) => {
        client.value.role = simpleCrypto.encrypt(resp.data?.groups);
        return resp.data;
      });
    };

    const setClientViewedBanner = (bannerId) => {
      markBannerAsViewed(bannerId);
      if (!client.value.data.closedBanners) {
        client.value.data.closedBanners = [];
      }
      if (!client.value.data.closedBanners.includes(bannerId)) {
        client.value.data.closedBanners.push(bannerId);
      }
    };

    const updateStoredPartner = (partner) => {
      client.value.partner = simpleCrypto.encrypt(partner);
    };

    return {
      client,
      setClient,
      setClientViewedBanner,
      isProPlan,
      getItem,
      isAuthenticated,
      decodedPartner,
      setPartner,
      updateStoredPartner,
      updateStoreMe,
      isSysAdmin,
      isBetaGroup,
      region,
      isLoading,
      startLoading,
      stopLoading,
      isBannerViewed,
    };
  },
  {
    persist: {
      storage: localStorage,
      paths: [
        "client.data.firstName",
        "client.data.lastName",
        "client.data.phoneNumber",
        "client.data.email",
        "client.data.closedBanners",
        "client.data.partnerId",
        "client.data.welcomeViewed",
        "client.billingInfoUrl",
        "client.role",
        "client.partner.selectedPackage",
        "client.partner",
        "client.tinyMce",
      ],
    },
  }
);
